import { AdvancedImage } from "@cloudinary/react";
import React, { useState } from "react";
import {
  FaExclamationCircle,
  FaLocationArrow,
  FaShoppingCart,
  FaUser,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import classNames from "classnames";

const SignedInMobile = ({
  itemInCart,
  handleOpenSearch,
  handleSubmitSearch,
  handleChange,
  querySearchValue,
  openSearch,
  searchInput,
  businesses,
  hide,
  logo,
  address,
}) => {
  const navigate = useNavigate();
  const [showNavLinks, setShowNavLinks] = useState(false);

  const handleHamburgerClick = () => {
    setShowNavLinks(!showNavLinks);
  };

  const handleNavLinks = (e) => {
    navigate(`/main/type/${e.target.innerText}`);
    setShowNavLinks(false);
  };

  const handleSignout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <header>
      <div
        className={classNames("overlay", { "overlay-active": showNavLinks })}
        onClick={handleHamburgerClick}
      ></div>
      <nav className="mobile-nav-bar">
        <div className={showNavLinks ? "mobile-show" : "mobile-hide"}>
          <AdvancedImage
            className="signedIn-logo mobile-nav-bar-icon"
            cldImg={logo}
          />
          <div className="mobile-nav-bar-container">
            <h2>Categories</h2>
            <ul className="mobile-nav-bar-links">
              <li onClick={handleNavLinks}>clothing and accessories</li>
              <li onClick={handleNavLinks}>beauty and skincare</li>
              <li onClick={handleNavLinks}>handmade goods</li>
              <li onClick={handleNavLinks}>health and wellness</li>
            </ul>
          </div>
          <div className="mobile-nav-bar-container">
            <h2>Help & Settings</h2>
            <ul className="mobile-nav-bar-links">
              <Link to="/support">
                <li>Customer support</li>
              </Link>
              <Link to="/profile">
                <li>Account</li>
              </Link>
              <li onClick={handleSignout}>Sign Out</li>
            </ul>
          </div>
        </div>

        <div className="mobile-nav-top">
          <div className="open">
            <GiHamburgerMenu
              className={showNavLinks ? "hamburger" : "hamburger-active"}
              onClick={handleHamburgerClick}
              size={25}
              color="black"
            />
            <Link to="/main">
              <AdvancedImage className="signedIn-logo" cldImg={logo} />
            </Link>
          </div>
          <div className="cart-icons-mobile">
            <Link to="/profile">
              <FaUser size={20} color="black" />
            </Link>
            <div className="cart-animation">
              {itemInCart && (
                <FaExclamationCircle
                  className="circleExclamation"
                  color="red"
                  size={12}
                />
              )}
              <Link to="/checkout">
                <FaShoppingCart size={20} color="black" />
              </Link>
            </div>
          </div>
        </div>

        <div className="mobile-nav-middle">
          <div className="signedInSearch-container">
            {!hide && (
              <input
                value={querySearchValue || searchInput.signedInSearch}
                onChange={handleChange}
                onClick={() => handleOpenSearch(true)}
                placeholder="Know what business you're looking for?"
                type="search"
                name="signedInSearch"
                id="signedInSearch"
              />
            )}
            {openSearch && (
              <div className="search-drop-down">
                <div className="search-drop-down-wrapper">
                  <div
                    className="close-search"
                    onClick={() => handleOpenSearch(false)}
                  >
                    <span>Close</span>
                  </div>
                  <div className="search-drop-down-wrapper-flex">
                    {businesses &&
                      searchInput.signedInSearch.length > 0 &&
                      businesses
                        .filter((business) =>
                          business.businessName
                            .toLowerCase()
                            .includes(searchInput.signedInSearch.toLowerCase())
                        )
                        .slice(0, 6)
                        .map((business) => (
                          <Link
                            to={`/main/business/${business.businessName}`}
                            onClick={handleSubmitSearch}
                            className="search-bar-item"
                            key={business._id}
                          >
                            <img
                              src={business.iconPhoto}
                              alt={business.businessName}
                            />
                            <p>{business.businessName}</p>
                          </Link>
                        ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Link to="/profile" className="mobile-nav-bottom">
          <h2>
            {address?.address ? (
              <>
                <FaLocationArrow /> {address.address.streetAddress}
              </>
            ) : (
              <span>
                <FaLocationArrow /> Click to add your delivery address
              </span>
            )}
          </h2>
        </Link>
      </nav>
    </header>
  );
};

export default SignedInMobile;
