import React, { useCallback, useEffect, useState } from "react";
import "./cart.css";
import DeliveryDetails from "./DeliveryDetails";
import Receipt from "./Receipt";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import haversineDistance from "../haversineDistanceFormula";
import OrderInProgress from "./OrderInProgress";

const Cart = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState({});
  const [cart, setCart] = useState([]);
  const [tip, setTip] = useState(0.0);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const [renderStripePayment, setRenderStripePayment] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderInProgress, setOrderInProgress] = useState(false);

  const [deliveryInstructions, setDeliveryInstructions] = useState({
    deliverInstructions: "None",
    noContactDelivery: "false",
  });

  const handleTip = (e) => {
    const tipSelected = e.target.innerText;
    const tipAmount = parseInt(tipSelected);
    setTip(tipAmount);
  };

  const handleDeliveryInstructions = (e) => {
    const { name, value } = e.target;
    setDeliveryInstructions((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleSubmitInstruction = async (e) => {
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/profile/update/delivery-instructions`,
        deliveryInstructions,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error(
        "There was an error with adding your delivery instructions. Please try again",
        error
      );
    }
  };

  const fetchProfileData = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/address`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAddress(response.data.address);
    } catch (error) {
      console.error("There was a network error. Please try again in a moment.");
    }
  };

  const fetchCart = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/cart`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCart(response.data)
    } catch (error) {
      console.error(
        "There was a network error, Please try again in a moment.",
        error
      );
    }
  };

  function calculateDeliveryFee(distance) {
    const baseFee = 9.75;
    const additionalChargePerMile = 0.75;
    const baseDistance = 5;
    if (distance <= baseDistance) {
      return baseFee;
    } else if (distance <= 15) {
      return baseFee + additionalChargePerMile * (distance - baseDistance);
    } else {
      console.error("Distance exceeds 15-mile limit");
    }
  }

  const findTheDistance = useCallback(() => {
    try {
      if (
        address.address &&
        cart.restaurantAddress &&
        cart.restaurantAddress.length > 0
      ) {
        const businessCoords = cart.restaurantAddress[0].address;
        const distance = haversineDistance(
          address.address.latitude,
          address.address.longitude,
          businessCoords.latitude,
          businessCoords.longitude
        );
        const deliveryFee = calculateDeliveryFee(distance);
        setDeliveryFee(deliveryFee);
      } else {
        setDeliveryFee(0.001)
      }
    } catch (error) {
      console.error(
        "There was an error calculating your delivery fee, please try again.",
        error
      );
    }
  }, [address, cart]);

  const checkOrderInProgress = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/profile/order-status`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setOrderInProgress(response.data.orderInProgress);
    } catch (error) {
      console.error("There was an error fetching your order status");
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/");
    } else {
      fetchCart();
      fetchProfileData();
      window.scrollTo(0, 0);
    }
  }, [navigate]);

  useEffect(() => {
    checkOrderInProgress();
  }, []);

  useEffect(() => {
    findTheDistance();
  }, [address, cart]);

  useEffect(() => {
    if (address && cart && deliveryFee) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [address, cart, deliveryFee]);

  return loading ? (
    <div className={`loading-container ${loading ? "show" : ""}`}>
      <img
        src="https://res.cloudinary.com/dtlf7hj7b/image/upload/v1727233367/B-Street_tfu6uv.png"
        className="loading-icon"
        alt="The B Street loading icon"
      />
    </div>
  ) : (
    <React.Fragment>
      {orderInProgress ? (
        <OrderInProgress />
      ) : (
        <div className="cart">
          <DeliveryDetails
            handleSubmitInstruction={handleSubmitInstruction}
            setRenderStripePayment={setRenderStripePayment}
            handleDeliveryInstructions={handleDeliveryInstructions}
            handleTip={handleTip}
            address={address}
            cart={cart}
          />
          <Receipt
            orderInProgress={orderInProgress}
            deliveryFee={deliveryFee}
            address={address}
            renderStripePayment={renderStripePayment}
            setRenderStripePayment={setRenderStripePayment}
            tip={tip}
            setCart={setCart}
            cart={cart}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Cart;
