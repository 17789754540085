import React from 'react';

const isStoreOpen = (openTime, closeTime) => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  const currentMinute = currentTime.getMinutes();

  const [openHour, openMinute] = openTime.split(':').map(Number);
  const [closeHour, closeMinute] = closeTime.split(':').map(Number);

  const openTimeInMinutes = openHour * 60 + openMinute;
  const closeTimeInMinutes = closeHour * 60 + closeMinute;
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  if (closeTimeInMinutes < openTimeInMinutes) {
    return currentTimeInMinutes >= openTimeInMinutes || currentTimeInMinutes <= closeTimeInMinutes;
  }
  return currentTimeInMinutes >= openTimeInMinutes && currentTimeInMinutes <= closeTimeInMinutes;
};

const formatTimeToAMPM = (time) => {
  const [hour, minute] = time.split(':').map(Number);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12; 
  const formattedMinute = minute < 10 ? `0${minute}` : minute;  
  return `${formattedHour}:${formattedMinute} ${ampm}`;
};

const RestaurantAbout = ({ filteredBusinesses }) => {
  const currentDayIndex = new Date().getDay(); 

  const dayMapping = {
    sunday: 0,
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
  };

  return (
    <div className="restaurant-about">
      <div className="restaurant-about-container">
        {filteredBusinesses &&
          filteredBusinesses.map((business) => (
            <div key={business._id} className="about">
              <div className="restaurant-map">
                <div className="restaurant-hours">
                  <ul>
                    {Object.keys(business.storeOperations?.operatingHours).map((day) => {
                      const dayIndex = dayMapping[day.toLowerCase()];
                      
                      if (dayIndex === currentDayIndex) {
                        const dayHours = business.storeOperations.operatingHours[day];
                        const isOpen = isStoreOpen(dayHours.open, dayHours.close);
                        const dayName = day.charAt(0).toUpperCase() + day.slice(1);
                        return (
                          <li key={day} className={isOpen ? 'open' : 'closed'}>
                            {dayName} {formatTimeToAMPM(dayHours.open)} - {formatTimeToAMPM(dayHours.close)}{' '}
                            <span className={isOpen ? 'status open' : 'status closed'}>
                              {isOpen ? 'Open' : 'Closed'}
                            </span>
                          </li>
                        );
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RestaurantAbout;